import { React, useState } from 'react'

import cont from '../images/marker.svg'


const Subscribe = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const response = await fetch("/api/contact", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        alert('Email sent successfully!');
        setFormData({
          name: '',
          email: '',
          message: ''
        });
      } else {
        throw new Error('Email sending failed.');
      }
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };
  
  return (

    <div>
      <div className="section py-3 py-lg-5 px-2 px-lg-4 " id="contact">
        <div className="container-narrow">
          <div className="text-center mb-5">
            <h2 className="marker marker-center">Contact Us</h2>
            <img src={cont} alt='cont' />
          </div>
          <div className="row">
            <div className="col-md-6" data-aos="zoom-in" data-aos-delay="100">

              <div className="bg-light my-2 p-3 pt-2">
                <form onSubmit={handleSubmit}>
                  <div className="form-group my-2">
                    <label forhtml="name" className="form-label fw-bolder">Name</label>
                    <input
                      className="form-control" type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required />
                  </div>


                  <div className="form-group my-2">
                    <label forhtml="email" className="form-label fw-bolder">Email</label>
                    <input
                      className="form-control"
                      type="text"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required />
                  </div>

                  <div className="form-group my-2">
                    <label forhtml="message" className="form-label fw-bolder">Message</label>
                    <textarea
                      className="form-control"
                     id="message"
                      name="message"
                      rows={4}
                      value={formData.message}
                      onChange={handleChange}
                      required>
                    </textarea>
                  </div>
                
                  <button className="btn btn-warning mt-2" type="submit">Send</button>
                </form>
              </div>
           </div>
            <div className="col-md-6" data-aos="fade-left" data-aos-delay="300">
              <div className="mt-3 px-2">
                <div className="h5">Let’s talk how we can help you!</div>
                <p>If you like to work with us then drop us a message using the contact form.</p>
                <p>Or get in touch using  email, skype or contact number.</p>
                <p>See you!</p>
              </div>
              <div className="mt-5 px-2">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="pb-1">Email:</div>
                  </div>
                  <div className="col-sm-10">
                    <div className="pb-1 fw-bolder">MandavTech@company.com</div>
                  </div>
                  <div className="col-sm-2">
                    <div className="pb-1">Skype:</div>
                  </div>
                  <div className="col-sm-10">
                    <div className="pb-1 fw-bolder">MandavTech@skype.com</div>
                  </div>
                  <div className="col-sm-2">
                    <div className="pb-1">Phone:</div>
                  </div>
                  <div className="col-sm-10">
                    <div className="pb-1 fw-bolder">+0718-111-0011</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subscribe
