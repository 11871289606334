import React from 'react'
import { useState } from 'react'
import './about.css'


/**Images:- */
import abd from '../images/devlop.svg'
import lemo from '../images/team.svg'
import memo from '../images/chat.svg'
import zoro from '../images/client2.png'
import horo from '../images/client3.png'
import loro from '../images/client4.png'
import toro from '../images/client5.png'
import moro from '../images/client6.png'
import foro from '../images/client7.png'
import xom from '../images/marker.svg'
import momo from '../images/marker.svg'



const About = () => {




  const [showParagraph, setShowParagraph] = useState(false);
  const handleClick = () => {
  setShowParagraph(!showParagraph);}



  const [showthis, setShowthis] = useState(false);
  const handleeClick = () => {
  setShowthis(!showthis);}



  const [showtummy, setShowtummy] = useState(false);
  const handlClick = () => {
  setShowtummy(!showtummy);}



 const [showme, setShowme] = useState(false);
 const handluClick=()=>{
  setShowme(!showme);}



  return (
    <>
      <div className="section pt-3 pt-lg-5 px-2 px-lg-4" id="about">
        <div className="container-narrow">
          <div className="text-center mb-4">
            <h2 className="marker marker-center">Work With Us</h2>
            <img src={xom} alt=' ' />
          </div>
          <div className="text-center ">
            <p align="justify" className="mx-auto mb-3 text-center " style={{ maxwidth: "600px"}}><small>In today’s dynamic business landscape, partnering with an 
adaptable and experienced professional is paramount to success. In 
essence, by engaging our services, you're not just hiring a skilled 
professional; you're enlisting a strategic partner committed to enhancing 
your digital presence, optimizing operations, and driving sustained 
business growth. Let's embark on this journey together, turning your 
vision into a success story!</small>
</p>
          </div>
        </div>
        <div className="container">
          <div className="row py-3">
            <div className=" col-md-6 col-md-2 text-center" data-aos="fade-up" data-aos-delay="100">
              <div className="border rounded p-4 bg-light ">
                <div className="text-center text-secondary display-3"><i className="bi bi-palette-fill"><svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="currentColor" className="bi bi-palette-fill" viewBox="0 0 16 16">
                  <path d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 1 0-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07M8 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m4.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3M5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                </svg></i></div>
                <h3 className="h4 mt-2 ">Design</h3>
                <p align="justify"className="text-small"><small>Effective web design seamlessly blends aesthetics and functionality, 
creating visually compelling and user-friendly interfaces. In digital 
marketing, strategic design optimizes brand visibility and engagement, 
driving conversions. A harmonious fusion of creativity and technology, 
design in web development and digital marketing is the key to 
captivating online experiences and business success</small></p>
              </div>
            </div>
            <div className="col-lg-6 col-lg-3 text-center " data-aos="fade-up" data-aos-delay="200">
              <div className="border rounded p-5 bg-light mb-3 ">
                <div className="text-center text-secondary display-3"><i className="bi bi-chat-left-text-fill"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-chat-left-text-fill" viewBox="0 0 16 16">
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z" />
                </svg></i></div>
                <h3 className="h4 mt-2">Feedback</h3>
                <div>
                  <p align="justify"className="text-xs"><small>Feedback in web development and digital marketing is a compass for improvement. User input refines website functionality, ensuring optimal 
experiences. In marketing, client feedback refines strategies, enhancing 
campaign effectiveness.</small> </p></div>
              </div>
            </div>
            <div className=" col-md-6 col-md-2 text-center" data-aos="fade-up" data-aos-delay="300">
              <div className="border rounded p-4 bg-light">
                <div className="text-center text-secondary display-3"><i className="bi bi-calendar-check-fill">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2m-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                  </svg></i></div>
                <h3 className="h4 mt-2">Execution</h3>
                <p align="justify"className="text-small"><small>Execution in web development and digital marketing refers to the 
process of implementing a strategy. In web development, it involves 
developing software that affects every aspect of a business In digital 
marketing, precise execution of strategies ensures targeted outreach, 
engagement, and measurable results. The synergy of precise execution 
fuels success, turning concepts into impactful online experiences and 
marketing triumphs."</small>
</p>
              </div>
            </div>
            <div className="col-lg-6 col-lg-2 text-center " data-aos="fade-up" data-aos-delay="400">
              <div className=" border rounded p-5 bg-light mb-3 ">
                <div className="text-center text-secondary display-3"><i className="bi bi-people-fill"></i><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                </svg></div>
                <h3 className="h4 mt-4">Support</h3>
                <p align="justify" className="mb-2"><small>In web development, robust support is the backbone, ensuring site reliability, swift issue resolution, and optimal user experiences. 
Similarly, in digital  marketing , dedicated support refines campaigns, 
adapting  strategies  to evolving trends and client goals.</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="row py-3 py-lg-5">
          <div className="col-lg-6 col-md-5 px-3 order-md-last" data-aos="fade-zoom-in" data-aos-delay="50" data-aos-easing="ease-in-back"><img className="my-3 img-fluid" src={abd} alt="development" /></div>
          <div className="col-lg-6 col-md-7 px-2" data-aos="zoom-in" data-aos-delay="100">
            <div className="h3 mb-2">Let our <strong> Team</strong> understand and serve your needs.</div>
            <p align="justify" className="text-secondary mb-3"><small>Let our team understand and serve your needs in web development and digital marketing. We blend innovative web design, seamless functionality, and strategic marketing to elevate your online presence. With a customer-centric approach, we ensure tailored solutions that resonate, engage, and drive success in the ever evolving digital landscape."</small></p>

   

             <div className="row">
              <div className="col-md-6 mb-2">
                <div className="border border-3 rounded px-2 py-2 d-flex align-items-center bg-light">
                  <div className="badge bg-warning me-2"><i className="fa fa-check"></i></div>
                  <div className="text-secondary fw-bold">
                  <div>
                <button className='btn btn secondary' onClick={handleClick}>
                {showParagraph ? 'Active Collaboration' : "Active Collaboration"}
                </button>
                {showParagraph && <p align="justify">In our web development and digital marketing company, active 
                collaboration is our ethos. We thrive on synergy, combining creative insights and technical expertise. Engaging closely with clients, we transform visions into reality, ensuring dynamic websites and impactful campaigns that resonate. Together, we build a digital presence that stands out and succeeds</p>}
</div>
                  
                    </div>
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className="border border-3 rounded px-2 py-2 d-flex align-items-center bg-light">
                  <div className="badge bg-warning me-2"><i className="fa fa-check"></i></div>
                  <div className="text-secondary fw-bold">
                  <div>
                <button className='btn btn secondary' onClick={handleeClick}>
                {showthis ? 'On-Schedule Delivery' : "On-Schedule Delivery"}
                </button>
                {showthis && <p align="justify">In our web development and digital marketing company, on schedule delivery is paramount. We prioritize timelines, ensuring prompt execution of projects with precision. From dynamic websites to strategic campaigns, our commitment to timely delivery guarantees clients receive exceptional solutions that align seamlessly with their goals, fostering trust and success.</p>}
    </div>
                    </div>
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className="border border-3 rounded px-2 py-2 d-flex align-items-center bg-light" style={{background:""}}>
                  <div className="badge bg-warning me-2"><i className="fa fa-check"></i></div>
                  <div className="text-secondary fw-bold">
                  <div>
                <button className='btn btn secondary' onClick={handlClick}>
                {showtummy ? 'After Sales & service' : "After Sales & service"}
                </button>
                {showtummy && <p align="justify">After-sales service is our commitment in web development and digital marketing. Beyond project completion, we offer continuous support, ensuring websites run seamlessly and campaigns thrive. Our dedicated team provides ongoing assistance, troubleshooting, and optimizations, fostering lasting partnerships and ensuring sustained success for our clients in the digital realm.</p>}
    </div>
                </div>
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className="border border-3 rounded px-2 py-2 d-flex align-items-center bg-light">
                    <div className="badge bg-warning me-2"><i className="fa fa-check"></i></div>
                    <div className="text-secondary fw-bold">
                    <div>
                <button className='btn btn secondary' onClick={handluClick}>
                {showme ? '24x7*** On-Support' : "24x7*** On-Support"}
                </button>
                {showme && <p align="justify">Embracing client needs around the clock, our web development and digital marketing company delivers 24x7 on-support. With an unwavering commitment, our dedicated team ensures continuous assistance, troubleshooting, and strategic guidance.</p>}
</div>
                      </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div className="row py-1 py-lg-5">
        <div className="col-lg-6 col-md-5 px-3" data-aos="fade-zoom-in" data-aos-delay="50" data-aos-easing="ease-in-back"><img className="my-3 img-fluid" src={lemo} alt="creative-team" /></div>
        <div className="col-lg-6 col-md-7 px-2" data-aos="zoom-in" data-aos-delay="100">
          <div className="h3 mb-2">Our <strong>Expert Team</strong> delivers the best result.</div>
          <p align="justify" className="text-secondary mb-2"><small>Our skilled team makes top-notch websites and effective digital marketing happen. We blend creativity and tech smarts to create awesome sites and successful campaigns. We focus on results, making sure our clients shine online and achieve their goals in a 
          standout way. Count on us for digital success!</small></p>
          <div>
            <div className="py-1 float-right">
              <div className="d-flex text-small fw-bolder"><span className="me-auto">Web Design</span><span>90%</span></div>
              <div className="progress my-1">
                <div className="progress-bar bg-warning" role="progressbar" data-aos="zoom-in-right" data-aos-delay="300" data-aos-anchor=".skills-section" style={{ width: "90%", ariavaluenow: "90", ariavaluemin: "0", ariavaluemax: "100" }}></div>
              </div>
            </div>
            <div className="py-1">
              <div className="d-flex text-small fw-bolder"><span className="me-auto">Mobile App</span><span>90%</span></div>
              <div className="progress my-1">
                <div className="progress-bar bg-secondary" role="progressbar" data-aos="zoom-in-right" data-aos-delay="400" data-aos-anchor=".skills-section" style={{ width: "90%", ariavaluenow: "90", ariavaluemin: "0", ariavaluemax: "100" }}></div>
              </div>
            </div>
            <div className="py-1">
              <div className="d-flex text-small fw-bolder"><span className="me-auto">Graphic Design</span><span>80%</span></div>
              <div className="progress my-1">
                <div className="progress-bar bg-warning" role="progressbar" data-aos="zoom-in-right" data-aos-delay="500" data-aos-anchor=".skills-section" style={{ width: "85%", ariavaluenow: "75", ariavaluemin: "0", ariavaluemax: "100" }}></div>
              </div>
            </div>
            <div className="py-1">
              <div className="d-flex text-small fw-bolder"><span className="me-auto">Digital Marketing</span><span>75%</span></div>
              <div className="progress my-1">
                <div className="progress-bar bg-secondary" role="progressbar" data-aos="zoom-in-right" data-aos-delay="600" data-aos-anchor=".skills-section" style={{ width: "75%", ariavaluenow: "75", ariavaluemin: "0", ariavaluemax: "100" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row py-3 py-lg-5 ms-2">
        <div className="col-lg-6 col-md-5 px-3 order-md-last" data-aos="fade-zoom-in" data-aos-delay="50" data-aos-easing="ease-in-back"><img className="my-3 img-fluid" src={memo} alt="work-chat" /></div>
        <div className="col-lg-6 col-md-7 px-2" data-aos="zoom-in" data-aos-delay="100">
          <div className="h3 mb-2 mr-2">We believe in <strong>Long Term Partnership</strong>.</div>
          <p align="justify"className="text-secondary mb-3"><small>We value lasting relationships in our web development and digital marketing company. Committed to fostering enduring partnerships, we prioritize client success over the long term.We're here for you, offering customized solutions, continuous support, and ongoing improvements. Let's build trust and grow together for lasting success in the digital world!</small></p>
          <ul className="nav nav-tabs nav-fill" role="tablist">
            <li className="nav-item" role="presentation"><a className="nav-link p-1 active" aria-current="page" href="#experience" data-bs-toggle="tab"><span className="badge bg-warning me-1">1</span> Experience</a></li>
            <li className="nav-item" role="presentation"><a className="nav-link p-1" href="#flexibility" data-bs-toggle="tab"><span className="badge bg-warning me-1">2</span> Flexibility</a></li>
            <li className="nav-item" role="presentation"><a className="nav-link p-1" href="#results" data-bs-toggle="tab"><span className="badge bg-warning me-1">3</span> Results</a></li>
          </ul>
          <div className="tab-content border border-top-0 text-secondary p-3 bg-light" id="myTabContent">
            <div className="tab-pane fade show active" id="experience" role="tabpanel" aria-labelledby="home-tab">
             <p align="justify "><small>In web development and digital marketing, experience matters. Our seasoned team brings years of expertise, creating polished websites and effective campaigns. With a wealth of know-how, we 
             navigate challenges, optimize strategies, and deliver results. Trust us to turn our experience into your digital success story.</small>
</p>
            </div>
            <div className="tab-pane fade" id="flexibility" role="tabpanel" aria-labelledby="profile-tab">
              <p align="justify"><small>Flexibility is our forte in web development and digital marketing. Tailoring solutions to fit your specific needs, our team crafts versatile websites and campaigns. We pride ourselves on adaptability, tweaking strategies for the best results. Choose us for solutions that flex and flow with your business, ensuring enduring success in the digital realm.</small></p>
            </div>
            <div className="tab-pane fade" id="results" role="tabpanel" aria-labelledby="contact-tab">
              <p align="justify"><small>At our web development and digital marketing company, results speak louder than words. We're dedicated to achieving measurable success—whether it's crafting compelling websites or executing impactful campaigns. With a focus on your goals, we deliver concrete outcomes, ensuring your digital presence translates into real, meaningful results that set you apart.</small></p>
            </div>
          </div>
        </div>
      </div>


      <div className="section py-3 py-lg-5 px-2 px-lg-4" id="clients">
        <div className="container-narrow">
          <div className="text-center mb-4">
            <h2 className="marker marker-center">Trusted By</h2>
            <img src={momo} alt='momo' />
          </div>
          <div className="text-center">
            <p className="mx-auto mb-3" style={{ maxwidth: "800px" }}><h5>Collaboratively administrate empowered markets via plug-and-play networks.</h5></p>
          </div>
          <div className="text-center" data-aos="zoom-in-up" data-aos-delay="100">
            <div className="mx-auto mb-3">
              <img className="p-1" src={zoro} alt='to' />
              <img className="p-1" src={horo} alt='for' />
              <img className="p-1" src={loro} alt='go' />
              <img className="p-1" src={toro} alt='rol' />
              <img className="p-1" src={moro} alt='ty' />
              <img className="p-1" src={foro} alt='fy' />
              
            </div>
          </div>
        </div>
      </div>




    </>
  )
}

export default About
