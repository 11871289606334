import React from 'react'
import "./team.css"

import jack from '../images/marker.svg'
import team01 from '../images/mandav1.jpg'
import team02 from '../images/mandav2.jpg'
import team03 from '../images/mandav3.jpg'
import team04 from '../images/mandav4.jpg'

import team05 from '../images/mandav5.jpg'
import team06 from '../images/mandav7.jpg'
import team07 from '../images/mandav8.png'
import team08 from '../images/mandav9.jpg'

import team09 from '../images/mandav10.jpeg'
import team10 from '../images/mandav11.jpg'
import team11 from '../images/mandav12.jpg'


const teamMembers = [
    {
        imgUrl: team01,
        name: 'MAHESH SHARMA',
        position: 'MD'
    },

    {
        imgUrl: team02,
        name: 'Jatinder Thakur',
        position: 'Full Stack Developer'
    },

    {
        imgUrl: team03,
        name: 'JATIN RANA',
        position: 'Angular Developer'
    },

    {
        imgUrl: team04,
        name: 'NITIN THAKUR',
        position: 'Designer & Developer'
    },
    
]


const myMembers = [
  {
      imgUrl: team05,
      name: 'SURBHHI SHARMA',
      position: 'HR'
  },

  {
      imgUrl: team06,
      name: 'NARENDER',
      position: 'Developer & Tester'
  },

  {
      imgUrl: team07,
      name: 'RAHUL SHARMA',
      position: 'Full Stack Developer'
  },

  {
      imgUrl: team08,
      name: 'PUNEET SHARMA',
      position: 'Wordpress Developer'
  },

 
  
]

const  myMemberTeam =[


  {
    imgUrl: team09,
    name: 'ANU',
    position: 'Developer'
},

{
  imgUrl: team10,
  name: 'MANOJ',
  position: 'Web Developer'
},

{
  imgUrl: team11,
  name: 'SANDEEP KAUR',
  position: 'Developer'
},
]






const Team = () => {
  return (
    <div>
       <div className='demo1' id='team'>
       <section className='our__team'>
            <div className='container'>
                <div className='team__content'>
         
                    <h2>
                         <span className='highlight'>Our Team</span>
                    </h2>
                    <img src={jack}alt='jack'/>
                </div>
                <div className='team__wrapper '>
                    {
                        teamMembers.map((item, index) => (
                            <div className='team__item' key={index}>
                                <div className='team__img'>
                                    <img src={item.imgUrl} alt='' />
                                </div>
                                <div className='team__details'>
                                    <h4>{item.name}</h4>
                                    <p className='description'>{item.position}</p>

                                    <div className='team__member-social'>
                                    <span><i style={{background:"#4267B2"}} class='fa fa-facebook'></i></span>
                                        <span><i style={{background:"#1DA1F2"}}class='fa fa-twitter'></i></span>
                                        <span><i style={{background:"#0077b5"}} class=' fa fa-linkedin'></i></span>
                                        <span><i style={{background:"red"}} class="fa fa-envelope"></i></span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='container'>
                <div className='team__content'>
                </div>
                <div className='team__wrapper'>
                    {
                        myMembers.map((item, index) => (
                            <div className='team__item' key={index}>
                                <div className='team__img'>
                                    <img src={item.imgUrl} alt='' />
                                </div>
                                <div className='team__details'>
                                    <h4>{item.name}</h4>
                                    <p className='description'>{item.position}</p>

                                    <div className='team__member-social'>
                                    <span><i style={{background:"#4267B2"}} class='fa fa-facebook'></i></span>
                                        <span><i style={{background:"#1DA1F2"}}class='fa fa-twitter'></i></span>
                                        <span><i style={{background:"#0077b5"}} class=' fa fa-linkedin'></i></span>
                                        <span><i style={{background:"red"}} class="fa fa-envelope"></i></span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='container'>
                <div className='team__content'>
                </div>
                <div className='team__wrapper '>
                    {
                        myMemberTeam.map((item, index) => (
                            <div className='team__item' key={index}>
                                <div className='team__img'>
                                    <img src={item.imgUrl} alt='' />
                                </div>
                                <div className='team__details'>
                                    <h4>{item.name}</h4>
                                    <p className='description'>{item.position}</p>

                                    <div className='team__member-social'>
                                    <span><i style={{background:"#4267B2"}} class='fa fa-facebook'></i></span>
                                        <span><i style={{background:"#1DA1F2"}}class='fa fa-twitter'></i></span>
                                        <span><i style={{background:"#0077b5"}} class=' fa fa-linkedin'></i></span>
                                        <span><i style={{background:"red"}} class="fa fa-envelope"></i></span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
        
    </div> 
    </div>
  )
}

export default Team
