import React from 'react'

import './portfolio.css'

/**--images--/ */

import jack from '../images/marker.svg'
import small1 from '../images/zoro1.jpg'
import small2 from '../images/zoro2.jpg'
import small3 from '../images/zoro12.png'
import small4 from '../images/zoro7.png'
import small6 from '../images/zoro9.png'
import small7 from '../images/zoro8.jpg'







const Portfolio = () => {





  return (
 <>
 <div className="section py-3 py-lg-5 px-2 px-lg-4" id="portfolio">
  <div className="container">
  <div className="text-center mb-5">
      <h2 className="marker marker-center">Portfolio </h2>
      <img src={jack}alt='jack'/>
    </div>
 <section className="wrapper">
    <div className="container-fostrap">
      
        <div className="content">
            <div className="container">
                <div className="row">
                <div className="col-xs-12 col-sm-4">
                        <div className="card">
                           
                            <img src={small1} alt='small1' />
                         
                            <div className="card-content">
                                <h4 className="card-title">
                                    Web apps Development
                                  
                                </h4>
                                <div>
                                <p align="justify" >

                                Web application development describes the process of designing, building, testing and deploying..
                                </p>
                              </div>
                            </div>
                           
                            
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <div className="card">
                            <img src={small2} alt='small2'/>
                            <div className="card-content">
                                <h4 className="card-title">
                                     Software Development
                                 
                                </h4>
                                <p align="justify" className="">
                                Software development refers to a set of computer science activities dedicated to the process of creating, designing, deploying .
                                </p>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <div className="card">
                          
                            <img src={small4} alt='small3'/>
                      
                            <div className="card-content">
                                <h4 className="card-title">
                                   Machine Learning Programming 
                                
                                </h4>
                                <p align="justify" className="">
                                Machine learning (ML) is a field of study in artificial intelligence concerned with  study of statistical algorithms.
                                </p>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <div className="card">
                           
                            <img src={small3} alt='small6' />
                         
                            <div className="card-content">
                                <h4 className="card-title">
                                  Ecommerce Development 
                                </h4>
                                <p align="justify" className="">
                               Ecommerce website can help you generate more conversions and revenue for your business.
                                </p>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <div className="card">
                           
                            <img src={small6} alt='small6' />
                         
                            <div className="card-content">
                                <h4 className="card-title">
                                   Business  and Admistrative Tools
                                </h4>
                                <p align="justify" className="">
                                Business management tools are all the systems, applications, controls, calculating solutions.
                                </p>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <div className="card">
                            <img src={small7}alt='small7' />
                            <div className="card-content">
                                <h4 className="card-title">
                                    Database Management
                                </h4>
                                <p align="justify" className="">
                                Managing a database involves designing,  and supporting stored data to maximize its value.</p>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
</div>
 
 
 </>

  
  )
}

export default Portfolio
