import React from 'react'

const Footer = ({websiteName}) => {
  return (
 <>
 <footer className="py-5 px-2 bg-light " id="footer">
  <div className="container">
    <div className="row"> 
      <div className="col-lg-4 col-md-12 mb-3">
        <div className="mb-3">
          <div className="h4">{websiteName}</div>
          <p className="text-muted"><small>Leverage agile frameworks to provide a robust synopsis for high level overviews.</small></p>
          <div className="social-nav">
            <nav role="navigation">
              <ul className="nav text-light">
                <li className="nav-item"><a className="nav-link" href="https://twitter.com/templateflip" title="Twitter"><i class="fa fa-twitter-square" style={{fontsize:"48px",color:"#1DA1F2"}}></i><span className="menu-title sr-only">Twitter</span></a></li>
                <li className="nav-item"><a className="nav-link" href="https://www.facebook.com/templateflip" title="Facebook"><i class="fa fa-facebook-square" style={{fontsize:"48px",color:"#1DA1F2"}}></i><span className="menu-title sr-only">Facebook</span></a></li>
                <li className="nav-item"><a className="nav-link" href="https://www.instagram.com/templateflip" title="Instagram"><i class="fa fa-instagram" style={{fontsize:"48px",color:"red"}}></i><span className="menu-title sr-only">Instagram</span></a></li>
                <li className="nav-item"><a className="nav-link" href="https://www.linkedin.com/" title="LinkedIn"><i class="fa fa-linkedin" style={{fontsize:"48px",color:"#0077b5"}}></i><span className="menu-title sr-only">LinkedIn</span></a></li>
                <li className="nav-item"><a className="nav-link" href="https://www.behance.net/templateflip" title="Behance"><i class="fa fa-behance" style={{fontsize:"48px",color:"#053eff"}}></i><span className="menu-title sr-only">Behance</span></a></li>
              </ul>
            </nav>
          </div>
        </div>
       
      </div>
      <div className="col-lg-2 col-md-6 mb-3">
        <div className="text-uppercase mb-2">Services</div>
        <div className="nav flex-column"><a className="nav-link px-0" href="#!">Design</a><a className="nav-link px-0" href="#!">Development</a><a className="nav-link px-0" href="#!">Branding</a><a className="nav-link px-0" href="#!">Marketing</a></div>
        
      </div>
    
      <div className="col-lg-2 col-md-6 mb-3">
        <div className="text-uppercase mb-2">Products</div>
        <div className="nav flex-column"><a className="nav-link px-0" href="/#services">Services</a><a className="nav-link px-0" href="#!">Portfolio</a><a className="nav-link px-0" href="/#team">Team</a><a className="nav-link px-0" href="#!">Pricing</a></div>
      </div>
     
      <div className="col-lg-2 col-md-6 mb-3">
        <div className="text-uppercase mb-2">About</div>
        <div className="nav flex-column"><a className="nav-link px-0" href="/#about">About</a><a className="nav-link px-0" href="#!">Contact</a><a className="nav-link px-0" href="#!">Terms</a><a className="nav-link px-0" href="#!">Privacy</a></div>
      </div>
      
      <div className="col-lg-2 col-md-6 mb-3">
        <div className="text-uppercase mb-2">Contact Us</div>
        <div className="text-small">
          <address>
           <small> Nexa Tower, F-338,Sector-74A,<br/>
            Mohali,Punjab<br/>
            India
          </small></address>
          <div className="mb-1"><strong>Phone:</strong><br/>+0718-111-0011</div>
          <div className="mb-1"><strong>Email:</strong><br/>MandavTech@gmail.com</div>
        </div>
      </div>
    </div>
  </div>
  
  <div className="text-small">
          <div  className=" text-center mb-1">&copy; Copyright <strong style={{color:"Green"}}>{websiteName}</strong>. All rights reserved.</div>
        
        </div>
  </footer>
 
 
 </>
  )
}

export default Footer
