import {React,useEffect,useState} from 'react';
import './navbar.css'

//images:-
import web from '../images/website.svg'
import dop from '../images/marker.svg'






const paragraph = {
   
  maxWidth: '100%',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 4,
  overflow: 'hidden',
  textOverflow: 'ellipsis',

}

const Navbar = ({ websiteName, onNameChange }) => {
  const [isSticky, setIsSticky] = useState(false);

   const handleInputChange = (e) => {
    const newName = e.target.value;
    onNameChange(newName); // Update the website name using the function from the parent component
   };

  const [activeItem, setActiveItem] = useState(null);

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    // Function to handle scroll event and set the active item based on scroll position
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const homeOffset = 0;
      const aboutOffset = 200;
      const servicesOffset = 600;
      const TeamOffset =1000;
      const portfolioOffset =900;
      const testimonialOffset= 1400;
      const contactOffset = 800;
   
     
      if (scrollPosition >= homeOffset && scrollPosition < aboutOffset) {
        setActiveItem('home');
      } else if (scrollPosition >= aboutOffset && scrollPosition < servicesOffset) {
        setActiveItem('about');
      } else if (scrollPosition >= servicesOffset && scrollPosition < TeamOffset) {
        setActiveItem('services');
      } else if (scrollPosition >= TeamOffset && scrollPosition < portfolioOffset) {
        setActiveItem('team');
      } else if (scrollPosition >= portfolioOffset && scrollPosition < testimonialOffset) {
      setActiveItem('portfolio');
    }else if (scrollPosition >= testimonialOffset && scrollPosition < contactOffset) {
      setActiveItem('testimonial');
    }else if (scrollPosition >= contactOffset) {
      setActiveItem('contact');
    };
      // Adjust this value based on your section heights or scroll positions
      
        const offset = window.scrollY;
        if (offset > 99) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
       
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


    


  // window.addEventListener("scroll", function () {
  //   const header = document.querySelector(".header")
  //   header.classList.toggle("sticky", window.scrollY > 100)

  // })

  
    

  return (
    <>
      <header className={`bg-light ${isSticky ? 'sticky' : ''}`}>
    
        <nav className="navbar navbar-expand-lg navbar-light bg-light " id="header-nav" role="navigation">
          <div className="container"><a className="link-dark navbar-brand site-title mb-0" href="#/">{websiteName}</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
            <div className="collapse navbar-collapse " id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto me-2">
                <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                <li className="nav-item"><a className="nav-link" href="/#about">About</a></li>
                <li className="nav-item"><a className="nav-link" href="/#services">Services</a></li>
                <li className="nav-item"><a className="nav-link" href="/#team">Team</a></li>
                <li className="nav-item"><a className="nav-link" href="/#portfolio">Portfolio</a></li>
                <li className="nav-item"><a className="nav-link" href="/#testimonials">Testimonials</a></li>
                <li className="nav-item"><a className="nav-link" href="/#contact">Contact</a></li>
                
                <li className="btn btn-warning btn-sm"> <a className="nav-link" href="/#about"> Started Here </a> 
                </li>
              </ul>
            </div>
          </div>
        </nav>

      </header>
      
      <div className="container px-2">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-5 pt-lg-5">
                <h3 className="intro-title marker" data-aos="fade-left" data-aos-delay="50">Your Success is Our Mission.</h3>
                <img src={dop} alt='dop' />
                <p align="justify" style={ isOpen ? null :paragraph } data-aos="fade-up" data-aos-delay="100"><h6>Welcome to {websiteName},where innovation meets 
                             expertise to craft compelling digital experiences. As a leading web 
                            development and Digital Marketing company, we specialize in transforming 
                            your ideas into visually stunning and highly functional websites. Our
                            dedicated team of skilled developers and designers is committed to delivering 
                            tailor-made solutions that align with your business objectives and specializes 
                            in providing comprehensive and cutting-edge solutions to elevate your online presence.
                            We're your dedicated website development and digital marketing
                            experts. Our services cover everything you need for a robust online 
                            presence, from business and e-commerce websites to sleek portfolios, 
                            online learning platforms, eye-catching landing pages, and efficient job 
                            portals. We pride ourselves on delivering user-friendly and innovative 
                            websites that meet the highest industry standards.</h6></p>
<button class='btn btn primary' onClick={()=>setIsOpen(!isOpen)}>{isOpen ?'Read less':'Read more'}</button>

                <div className="mt-3" data-aos="fade-up" data-aos-delay="200"><a className="btn btn-warning shadow-sm mt-1 me-2" href="#contact">Get Sarted <i className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                </svg></i></a><a className="btn btn-outline-secondary mt-1" data-bigpicture="{&quot;ytSrc&quot;: &quot;aqz-KE-bpKQ&quot;}" href="#/"> Watch Video</a></div>
              </div>
            </div>
            <div className="col-lg-6 p-3 pe-lg-0" data-aos="fade-left" data-aos-delay="100"><img className="pt-2 img-fluid" src={web} alt="hello" /></div>
          </div>
        </div>
      <section className="demo">
     
      </section>

    </>
  )
}

export default Navbar;
