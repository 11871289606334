import './App.css';
import { useState } from 'react';
import { BrowserRouter , Routes,Route} from "react-router-dom"
/*-----Bootstrap5---- */
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js'

/*----React Components----- */
import Navbar from './components/navbar/Navbar.js';
import About from './components/about/About.js';
import Myservice from './components/myservice/Myservice.js';
import Testimonial from './components/Testimonials/Testimonial.js';
import Subscribe from './components/subscribe/Subscribe.js';
import Footer from './components/footer/Footer.js';
import Portfolio from './components/portfolio/Portfolio.js';
import Team from './components/team/Team.js';
//import Started from './components/started/Started.js';





function App() {

  const [websiteName, setWebsiteName] = useState('Website Name ');

  // Function to update the website name
  const handleNameChange = (newName) => {
    setWebsiteName(newName);
  };



  return( 
    
   <>


<BrowserRouter>

<Navbar websiteName={websiteName} onNameChange={handleNameChange} />
<About />
<Myservice />
<Team />
<Portfolio />
<Testimonial />
<Subscribe />
<Footer websiteName={websiteName}  />




</BrowserRouter>

   </>
  )
}

export default App;
