import React from 'react'
import "./myservice.css"
import { useState } from 'react'

/**Images */
import bod from '../images/web1.svg'
import dod from '../images/web2.svg'
import mod from '../images/web3.svg'
import tom from '../images/marker.svg'

const paragraph = {

  maxWidth: '100%',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 4,
  overflow: 'hidden',
  textOverflow: 'ellipsis',

}


const para = {

  maxWidth: '100%',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 4,
  overflow: 'hidden',
  textOverflow: 'ellipsis',

}

const sold = {

  maxWidth: '100%',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
  textOverflow: 'ellipsis',

}


const Myservice = () => {
  
/*---Hooks--- */

  const [isOpen, setIsOpen] = useState(false)
  const [isOpn, setIsOpn] = useState(false)
  const [isOp, setIsOp] = useState(false)
  const [tone, settone] = useState(false)
  const [tim, settim] = useState(false)
 


  return (
    <div>
      <div className="section bg-light py-3 py-lg-5 px-2 px-lg-4" id="services">
        <div className="container-narrow">
          <div className="text-center mb-5">
            <h2 className="marker marker-center">Our Services</h2>
            <img src={tom} alt='topu' />
          </div>
          <div className="text-center">
            <p  className="mx-auto mb-3" style={{ maxwidth: "800px" }}><small>Quickly maximize timely deliverables for real-time schemas. Dramatically maintain clicks-and-mortar solutions without functional solutions.</small></p>
          </div>
          <div className="row py-3">
            <div className="col-md-4 text-center mb-3" data-aos="fade-up" data-aos-delay="100">
              <div className="bg-white border rounded-circle d-inline-block p-2"><img className="p-2" src={bod} width="96" height="96" alt="web design" /></div>
              <div className="bg-white border rounded p-3 mt-n4">
                <div className="h5 mt-3">Web Development</div>
                <p align="justify"  style={isOpen ? null : paragraph} className="text-small"><small>We are full-stack expertise covering frontend
                  (HTML,CSS,JavaScript) to backend (Node.js, Pythone, PHP).Our
                  web development service is dedicated to creating robust,
                  interactive, and visually appealing websites that cater to your
                  unique business needs. Whether you're launching a new online
                  venture, revamping an existing site, or seeking to optimize your
                  web presence, our team of experienced developers will make sure
                  that we deliver services as per your expectations and requirements.</small></p>
                  <button className='btn btn-warning' onClick={() => setIsOpen(!isOpen)}>{isOpen ? 'Read less' : 'Read more'}</button>
              </div>
            </div>
            <div className="col-md-4 text-center mb-3" data-aos="fade-up" data-aos-delay="200">
              <div className="bg-white border rounded-circle d-inline-block p-2"><img className="p-2" src={dod} width="96" height="96" alt="graphic design" /></div>
              <div className="bg-white border rounded p-3 mt-n4">
                <div className="h5 mt-3">Ecommerce Development</div>
                <p align="justify" style={isOpn ? null : paragraph} className="text-small"><small>With a team of experienced developers, designers, and strategists, we offer comprehensive Ecommerce development services that
                  cater to diverse industry verticals. We are proficient in Ecommerceplatform: Shopify, Magento, WooCommerce We specialize inIntegrating secure payment gateways for seamless transactions. We
                  believe in creating user-friendly, feature-rich online stores that seamlessly integrate with your business processes.</small></p>
                  <button  color="red" className='btn btn-warning' onClick={() => setIsOpn(!isOpn)}>{isOpn ? 'Read less' : 'Read more'}</button>
              </div>
            </div>
            <div className="col-md-4 text-center mb-3" data-aos="fade-up" data-aos-delay="300">
              <div className="bg-white border rounded-circle d-inline-block p-2"><img className="p-2" src={mod} width="96" height="96" alt="ui-ux" /></div>
              <div className="bg-white border rounded p-3 mt-n4">
                <div className="h5 mt-3">Database Management & Administration</div>
                <p align="justify" style={isOp ? null : sold} className="text-small"><small>With our Database Management and Administration service, you can focus on your core business operations while we take care of the intricate aspects of your data infrastructure. Trust us to keep your databases secure, performant, and always available, ensuring your data remains a valuable asset for your company. We are expert
                  in database installation, configuration and meticulous maintenance.</small></p>
                <button  className='btn btn-warning' onClick={() => setIsOp(!isOp)}>{isOp ? 'Read less' : 'Read more'}</button>
              </div>
            </div>
            <div className="col-md-4 text-center mb-3" data-aos="fade-up" data-aos-delay="200">
              <div className="bg-white border rounded-circle d-inline-block p-2"><img className="p-2" src={dod} width="96" height="96" alt="graphic design" /></div>
              <div className="bg-white border rounded p-3 mt-n4">
                <div className="h5 mt-3">Web & Mobile Design</div>
                <p align="justify" style={tone ? null : paragraph} className="text-small"><small>we specialize in creating visually stunning and user-friendly
                  interfaces for the websites and mobile apps that elevate your online
                  presence and enhance user experiences. We ensure responsive
                  design for seamless experience across devices. Our talented team of
                  designers combines creativity, cutting-edge technology, and user centric design principles to craft visually stunning and highly
                  functional websites.</small></p>
                  <button className='btn btn-warning' onClick={() => settone(!tone)}>{tone ? 'Read less' : 'Read more'}</button>
              </div>
            </div>


            <div className="col-md-4 text-center mb-3" data-aos="fade-up" data-aos-delay="200">
              <div className="bg-white border rounded-circle d-inline-block p-2"><img className="p-2" src={dod} width="96" height="96" alt="graphic design" /></div>
              <div className="bg-white border rounded p-3 mt-n4">
                <div className="h5 mt-3">Digital Marketing</div>
                <p align="justify" style={tim ? null : paragraph} className="text-small"><small>These days, you cannot have a successful business without a strong
                  online presence. A digital marketing strategy gives you direction and
                  focus. Without a strategy it’s hard to know what you need to do online to
                  achieve your business goals, and to measure how successful you are. We
                  will provide a custom-made framework that lists all the key digital
                  marketing activities needed for your business to enhance your online
                  presence and sales.</small></p>
                  <button className='btn btn-warning' onClick={() => settim(!tim)}>{tim ? 'Read less' : 'Read more'}</button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="section bg-secondary text-light py-3 py-lg-5 px-2 px-lg-4" id="stats">
        <div className="container-narrow">
          <div className="row px-4">
            <div className="col-md-3 col-sm-6">
              <div className="d-flex py-2"><i className="mr-3 fas fa-smile fa-4x fa-fw"></i>
                <div className="ms-1">
                  <div className="h2 mb-1 purecounter" data-purecounter-start="0" data-purecounter-end="325" data-purecounter-duration="1">325</div>
                  <div className="h6">Projects Done</div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="d-flex py-2"><i className="mr-3 fas fa-smile fa-4x fa-fw"></i>
                <div className="ms-1">
                  <div className="h2 mb-1 purecounter" data-purecounter-start="0" data-purecounter-end="145" data-purecounter-duration="1">145</div>
                  <div className="h6">Happy Clients</div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="d-flex py-2"><i className="mr-3 fas fa-file-alt fa-4x fa-fw"></i>
                <div className="ms-1">
                  <div className="h2 mb-1 purecounter" data-purecounter-start="0" data-purecounter-end="464" data-purecounter-duration="1">464</div>
                  <div className="h6">Appreciations</div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="d-flex py-2"><i className="mr-3 fas fa-clock fa-4x fa-fw"></i>
                <div className="ms-1">
                  <div className="h2 mb-1 purecounter" data-purecounter-start="0" data-purecounter-end="8565" data-purecounter-duration="1">8565</div>
                  <div className="h6">Support Hours</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Myservice



