import React from 'react'
import trick from '../images/client1.jpg'
import mojo from '../images/client2.jpg'
import tojo from '../images/client3.jpg'
import zoro from '../images/client4.jpg'
import tonystark from'../images/marker.svg'
const Testimonial = () => {
  return (
    <div>
      <div className="section py-3 py-lg-5 px-2 px-lg-4" id="testimonials">
  <div className="container-narrow">
    <div className="text-center mb-5">
      <h2 className="marker marker-center">Client Testimonials</h2>
      <img src={tonystark} alt='tonystark'/>
    </div>
    <div className="row"> 
      <div className="col-md-6 mb-5  bg-light" data-aos="fade-right" data-aos-delay="100">
        <div className="d-flex ms-md-3 "><span><i className='fa fa-quote-left'></i></span><span className="m-2">Bring to the table win-win survival strategies to ensure proactive domination. User generated content in real-time will have multiple touchpoints for offshoring.</span></div>
        <div className="d-flex justify-content-end align-items-end">
          <div className="text-end me-2">
            <div className="fw-bolder">Aiyana</div>
            <div className="text-small">CEO / Specely</div>
          </div><img className="me-md-3 rounded" src={trick} width="96" height="96" alt="client 1"/>
        </div>
      </div>
      <div className="col-md-6 mb-5  bg-light" data-aos="fade-left" data-aos-delay="300">
        <div className="d-flex ms-md-3"><span><i className='fa fa-quote-left'></i></span><span className="m-2">Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Organically grow the holistic world view of disruptive innovation.</span></div>
        <div className="d-flex justify-content-end align-items-end">
          <div className="text-end me-2">
            <div className="fw-bolder">Alexander</div>
            <div className="text-small">CEO / Combtree</div>
          </div><img className="me-md-3 rounded" src={mojo} width="96" height="96" alt="client 1"/>
        </div>
      </div>
      <div className="col-md-6 mb-5  bg-light" data-aos="fade-right" data-aos-delay="100">
        <div className="d-flex ms-md-3"><span><i className='fa fa-quote-left'></i></span><span className="m-2">Podcasting operational change management inside of workflows to establish a framework. Taking seamless key performance indicators offline to maximise potential.</span></div>
        <div className="d-flex justify-content-end align-items-end">
          <div className="text-end me-2">
            <div className="fw-bolder">Ariya</div>
            <div className="text-small">CTO / Softone</div>
          </div><img className="me-md-3 rounded" src={tojo} width="96" height="96" alt="client 1"/>
        </div>
      </div>
      <div className="col-md-6 mb-5  bg-light" data-aos="fade-left" data-aos-delay="300">
        <div className="d-flex ms-md-3"><span><i className='fa fa-quote-left'></i></span><span className="m-2">Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits.</span></div>
        <div className="d-flex justify-content-end align-items-end">
          <div className="text-end me-2">
            <div className="fw-bolder">Braiden</div>
            <div className="text-small">CFO / Markoo</div>
          </div><img className="me-md-3 rounded" src={zoro} width="96" height="96" alt="client 1"/>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Testimonial
